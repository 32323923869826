import {useState} from "react";
import {Box, Grid, MenuItem, Table, TableBody, TableCell, TableRow, Typography,} from "@mui/material";
import FlexTextField from "../../common/textfield/textfield";

import {AttachMoney, Delete} from "@mui/icons-material";
import Spacer from "../../common/util/spacer";
import FlexButton from "../../common/button/button";

interface DataRow {
  country: string;
  currency: string;
  monthlyPrice: number;
  annualPrice: number;
}

interface CountryPriceComponentProps {
  showMontly: boolean;
  showAnnually: boolean;
}

export default function CountryPriceComponent({
  showMontly,
  showAnnually,
}: CountryPriceComponentProps) {
  const createData = (
    country: string,
    currency: string,
    monthlyPrice: number,
    annualPrice: number
  ): DataRow => {
    return { country, currency, monthlyPrice, annualPrice };
  };

  const initialData: DataRow[] = [createData("India", "Rupee", 500, 1200)];

  const [country, setCountry] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [monthlyPrice, setMonthlyPrice] = useState<number>(0);
  const [annualPrice, setAnnualPrice] = useState<number>(0);
  const [data, setData] = useState<DataRow[]>(initialData);

  const handleAddRow = () => {
    const newRow = createData(country, currency, monthlyPrice, annualPrice);
    setData((prevData) => [...prevData, newRow]);
    setCountry("");
    setCurrency("");
    setMonthlyPrice(0);
    setAnnualPrice(0);
  };

  const handleDeleteRow = (index: number) => {
    setData((prevData) => prevData.filter((_, i) => i !== index));
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <FlexTextField
            placeHolder="Select Country"
            textLabel="Country"
            isDropDown
            fullWidth
            value={country}
            onChange={(value) => setCountry(value)}
          >
            {["India", "USA", "UK"].map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </FlexTextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FlexTextField
            placeHolder="Select Currency"
            textLabel="Currency"
            isDropDown
            fullWidth
            value={currency}
            onChange={(value) => setCurrency(value)}
          >
            {["USD", "INR", "GBP"].map((currency) => (
              <MenuItem key={currency} value={currency}>
                {currency}
              </MenuItem>
            ))}
          </FlexTextField>
        </Grid>

        {showMontly && (
          <Grid item xs={12} sm={6}>
            <FlexTextField
              placeHolder="Enter Monthly Price"
              textLabel="Monthly Price"
              inputType="number"
              fullWidth
              value={monthlyPrice.toString()}
              leadingIcon={<AttachMoney />}
              onChange={(value) => setMonthlyPrice(+value)}
            />
          </Grid>
        )}
        {showAnnually && (
          <Grid item xs={12} sm={6}>
            <FlexTextField
              placeHolder="Enter Annual Price"
              textLabel="Annual Price"
              inputType="number"
              fullWidth
              value={annualPrice.toString()}
              leadingIcon={<AttachMoney />}
              onChange={(value) => setAnnualPrice(+value)}
            />
          </Grid>
        )}
      </Grid>

      <Spacer height={20} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          width: "100%",
          borderRadius: "10px",
          paddingX: "10px",
          border: "1px solid gray",
        }}
      >
          <Typography sx={{fontFamily: "Inter", flex: 1}}>
          Country
        </Typography>
          <Typography sx={{fontFamily: "Inter", flex: 1}}>
          Currency
        </Typography>
          <Typography sx={{fontFamily: "Inter", flex: 1}}>
          Monthly Price
        </Typography>
          <Typography sx={{fontFamily: "Inter", flex: 1}}>
          Annual Price
        </Typography>
        <Box sx={{ padding: "10px" }}>
          <FlexButton text="+" onClick={handleAddRow} width={25} height={25} />
        </Box>
      </Box>

      <Table sx={{ width: "100%" }}>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.country}</TableCell>
              <TableCell>{row.currency}</TableCell>
              <TableCell>{row.monthlyPrice}</TableCell>
              <TableCell>{row.annualPrice}</TableCell>
              <TableCell
                sx={{
                  width: "30px",
                }}
              >
                <FlexButton
                  width={25}
                  height={25}
                  backgroundColor="transparent"
                  icon={<Delete sx={{ color: "gray" }} />}
                  onClick={() => handleDeleteRow(index)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
