import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModelFeature } from "./QuotationPage";
import FeatureTypeChip from "./FeatureTypeChip";
import FlexTextField from "../../components/common/textfield/textfield";
import FlexColors from "../../utils/colors";
import { formatNumber } from "../../utils/utility_functions";
import FlexDropdown from "../../components/common/dropdown/dropdown";

interface FeatureTableProps {
  initialFeatures: ModelFeature[];
  onQuantityChange: (slug: string, value: string) => void;
  onConfigOptionChange: (slug: string, value: string) => void;
  onDelete: (slug: string) => void;
}

export default function FeatureTable({
  initialFeatures,
  onQuantityChange,
  onConfigOptionChange,
  onDelete,
}: FeatureTableProps) {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof ModelFeature | null>(null);

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleRequestSort = (property: keyof ModelFeature) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortComparator = (
    a: ModelFeature,
    b: ModelFeature,
    orderBy: keyof ModelFeature
  ): number => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const sortedRows = orderBy
    ? initialFeatures.slice().sort((a, b) => {
        return order === "desc"
          ? sortComparator(a, b, orderBy)
          : -sortComparator(a, b, orderBy);
      })
    : initialFeatures;

  const handleKeyDown = (event: any, index: number) => {
    event.preventDefault();
    const nextIndex = index + 1;
    if (nextIndex < inputRefs.current.length) {
      inputRefs.current[nextIndex]?.focus();
    }
  };

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, initialFeatures.length);
  }, [initialFeatures]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead
          sx={{
            backgroundColor: "#F7F7F7",
            borderRadius: 20,
            fontFamily: "Inter",
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                padding: "8px 8px",
                fontWeight: "bold",
                textAlign: "start", // Align center horizontally
              }}
            >
              ID
            </TableCell>
            <TableCell
              width={"50%"}
              sx={{
                padding: "8px 15px",
                fontWeight: "bold",
                textAlign: "start", // Align start for column labels
              }}
              sortDirection={orderBy === "featureName" ? order : false}
            >
              <TableSortLabel
                active={orderBy === "featureName"}
                direction={orderBy === "featureName" ? order : "asc"}
                onClick={() => handleRequestSort("featureName")}
              >
                Feature Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              width={"10%"}
              sx={{
                padding: "8px 15px",
                fontWeight: "bold",
                textAlign: "start", // Align center horizontally
              }}
              sortDirection={orderBy === "featureType" ? order : false}
            >
              <TableSortLabel
                active={orderBy === "featureType"}
                direction={orderBy === "featureType" ? order : "asc"}
                onClick={() => handleRequestSort("featureType")}
              >
                Type
              </TableSortLabel>
            </TableCell>
            <TableCell
              width={"20%"}
              sx={{
                padding: "8px 15px",
                fontWeight: "bold",
                textAlign: "start", // Align center horizontally
              }}
            >
              Value
            </TableCell>
            <TableCell sx={{ padding: "8px 8px", width: "5%" }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell
                align={"left"}
                sx={{
                  fontWeight: "700",
                  color: FlexColors.primary,
                }}
              >
                {row.featureName}
              </TableCell>
              <TableCell align={"center"}>
                <FeatureTypeChip featureType={row.featureType} />
              </TableCell>
              <TableCell>
                <div
                  style={{
                    width: "200px",
                  }}
                >
                  {row.featureType === "config" ? (
                    <FlexDropdown
                      onSelect={(selectedOption) => {
                        onConfigOptionChange(row.slug, selectedOption);
                      }}
                      options={row.configOptions}
                    />
                  ) : (
                    <FlexTextField
                      textLabel=""
                      placeHolder=""
                      inputType="tel"
                      value={
                        row.featureType === "boolean"
                          ? "Yes"
                          : formatNumber(row.requestedQuantity)
                      }
                      onChange={(value) => {
                        if (row.featureType !== "boolean") {
                          const numericValue = value.replace(/[^0-9]/g, "");
                          onQuantityChange(row.slug, numericValue);
                        }
                      }}
                      onKeyStroke={(event: React.KeyboardEvent) =>
                        handleKeyDown(event, index)
                      }
                      inputRef={(el: any) => (inputRefs.current[index] = el)}
                    />
                  )}
                </div>
              </TableCell>
              <TableCell>
                <IconButton
                  aria-label="delete"
                  onClick={() => onDelete(row.slug)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
