interface SpacerProps {
  height?: string | number;
  width?: string | number;
}

export default function Spacer({ height, width }: SpacerProps) {
  return (
    <div
      style={{
        height: height,
        width: width,
      }}
    />
  );
}
