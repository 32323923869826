import { useLocation, useNavigate } from "react-router-dom";
import InputField from "@/components/common/textfield/input_field";
import { useEffect, useState } from "react";
import { ArrowBackIos, DeleteForever } from "@mui/icons-material";
import { Alert, CircularProgress, Container, Divider, Snackbar } from "@mui/material";
import Spacer from "@/components/common/util/spacer";
import { AddOnAPI } from "@/api/req/add_on_api";
import FlexButton from "@/components/common/button/button";
import { ModelSnackbar } from "../sales/QuotationPage";
import { PriceTierV2 } from "../addon/AddOnPage";
import AddOnPageDropDown from "../addon/AddOnPageDropDown";
import { Plan } from "@/api/models/model_product_detail";
import { ProductAPI } from "@/api/req/product_api";
import { ModelProduct } from "@/api/models/model_product";
import { ProductDetailAPI } from "@/api/req/product_detail_api";
import FlexColors from "@/utils/colors";
import { extractPlan } from "../plans/PlanUtility";


interface PlanData {
  id: string;
  price_id: string | undefined;
  name: string;
  slug: string;
  tier_type: string;
  status: string;
  billing_period: string;
  price: PriceTierV2[] | number;
  updated_at: Date;
}

const centerLoaderStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute" as "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.8)",
};

const AddonEditPage = () => {
  const location = useLocation();
  const [plan, setPlan] = useState<Plan>()
  const [billingPeriod, setBillingPeriod] = useState<string>("MONTHLY");
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<PlanData | undefined>();

  const [initialPriceState, setInitialPriceState] = useState<
    PriceTierV2[] | number
  >();
  const [updating, setUpdating] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [snackBarInfo, setSnackBarInfo] = useState<ModelSnackbar>({
    title: "Alert MSG",
    isError: false,
  });
  const navigator = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const plan_id = searchParams.get('id');


  useEffect(() => {
    initialize()
  }, []);

  const initialize = () => {
    setLoading(true);
    ProductAPI.getAll().then((result: ModelProduct[]) => {
      ProductDetailAPI.getAll(result[0].id)
        .then((product) => {
          setLoading(false);
          const x_plan = product.add_ons.find((data) => data.id === plan_id)!;
          setPlan(x_plan);
          convertToModelPlan(x_plan, billingPeriod);
        });
    });
  }

  const convertToModelPlan = (plan: Plan, billingPeriod: string) => {
    setLoading(true);
    const plan_data = {
      id: plan!.id,
      name: plan!.name,
      slug: plan!.slug,
      billing_period: billingPeriod,
      status: plan!.status,
      updated_at: plan!.updated_at,
      ...extractPlan(plan!.prices || [], billingPeriod),
    }

    setFormData(plan_data);
    setLoading(false)

  };

  const handlePriceTierUpdate = () => {
    let payload = {};
    setUpdating(true);
    if (formData!.tier_type === "Flat fee") {
      payload = {
        ...payload,
        tier_type: "FLAT",
        billing_model: "FLAT",
        monthly_price: formData!.price,
        annual_price: (formData!.price as number) * 12,
      };
    }
    if (formData!.tier_type === "Tiered") {
      payload = {
        ...payload,
        tier_type: "VOLUME",
        billing_model: "PER_UNIT",
        price_tiers: [
          ...(formData!.price as PriceTierV2[]).map(
            (value: PriceTierV2, index: number) => {
              if (index === (formData!.price as []).length - 1) {
                return {
                  up_to: 999999999,
                  price: Number(value.price),
                  currency: "INR"
                };
              } else {
                return {
                  up_to: Number(value.to),
                  price: Number(value.price),
                  currency: "INR"
                };
              }
            }
          ),
        ],
      };
    } else {
      payload = {
        ...payload,
        monthly_price: formData!.price,
      };
    }

    console.log(payload);

    payload = {
      ...payload,
      billing_model: formData?.tier_type === "Flat fee" ? "FLAT_FEE" : "PER_UNIT",
      feature_id: plan?.feature_mappings?.[0]?.feature.id,
    }

    console.log(payload);


    if (formData!.price_id) {
      AddOnAPI.update(false, formData!.price_id!, payload).then((value) => {
        afterPriceUpdate(value);
      });
    } else {
      payload = {
        ...payload,
        plan_id: formData!.id,
        currency: "INR",
        billing_period: billingPeriod,
        billing_cadence: "RECURRING",
      }
      AddOnAPI.create(false, payload).then((value) => {
        afterPriceUpdate(value);
      });
    }

  };

  const afterPriceUpdate = (value: any) => {
    AddOnAPI.update_addon(false, plan!.id, { name: plan?.name });
    if (value) {
      setSnackBarInfo({
        title: "Price Tier updated successfully",
        isError: false,
      });
      setAlertOpen(true);
      setUpdating(false);
    } else {
      setSnackBarInfo({
        title: "Price Tier updated unsuccessfully",
        isError: true,
      });
      setAlertOpen(true);
      setUpdating(false);
    }
    initialize();
  }
  const handleTierChange = (
    index: number,
    field: keyof PriceTierV2,
    value: string
  ) => {
    const updatedTiers = (formData!.price as PriceTierV2[]).map((tier, i) => {
      if (i === index) {
        return { ...tier, [field]: value };
      }
      if (i == index + 1 && field === "to") {
        return { ...tier, from: Number(value) + 1 };
      }
      return tier;
    });
    setFormData({ ...formData!, price: updatedTiers! });
  };

  const addTier = () => {
    const lastTier: PriceTierV2 = (formData!.price as [])[
      (formData!.price as []).length - 1
    ];

    let newFrom;
    if (lastTier.to === "&above") {
      lastTier.to = (lastTier.from as number) + 1;
      newFrom = (lastTier.to as number) + 1;
    } else {
      newFrom = (lastTier.to as number) + 1;
    }

    setFormData({
      ...formData!,
      price: [
        ...(formData!.price as []),
        {
          from: newFrom,
          to: "&above",
          price: 0,
        },
      ],
    });
  };

  const removeTier = (index: number) => {
    const updatedTiers = (formData!.price as PriceTierV2[]).filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData!, price: updatedTiers });
  };

  return (
    <Container>
      {loading && (
        <div style={centerLoaderStyle}>
          <CircularProgress sx={{ "color": FlexColors.primary }} />
        </div>
      )}
      <div className="flex justify-between bg-white items-center rounded-md shadow-md mt-7 px-5 py-5">
        <div className="flex justify-start items-center">
          <button
            className="flex items-center text-gray-500 hover:text-gray-700"
            onClick={() => { navigator(-1); }}
          >
            <ArrowBackIos className="mr-2" />
          </button>
          <div className="font-bold text-gray-950 text-2xl">
            Edit price for{" "}
          </div>
          <div className="w-2"></div>
          <div className="font-bold text-orange-600 text-2xl"> {formData?.name ?? ""}</div>
        </div>
      </div>
      <Spacer height={20} />
      <div className="justify-between bg-white items-center rounded-md shadow-md px-5 py-5 space-y-5">
        <h6 className="font-bold text-orange-600 text-2xl">Add-on details</h6>
        <div>
          <h3 className="font-medium">
            Name<span className="text-red-600">*</span>
          </h3>
          <InputField placeholder={"name"} value={formData?.name ?? ""} />
        </div>
        <div>
          <h3 className="font-medium">
            Addon Slug<span className="text-red-600">*</span>
          </h3>
          <InputField placeholder={"slug"} value={formData?.slug ?? ""} />
        </div>
      </div>
      <Spacer height={20} />
      <div className="justify-between items-center rounded-md shadow-lg px-5 py-5 space-y-5 bg-white">
        <div className="justify-between items-center flex">
          <h6 className="font-bold text-orange-600 text-2xl">
            Pricing details
          </h6>
          <FlexButton
            height={40}
            isLoading={updating}
            textSize={16}
            text="Update Price"
            onClick={handlePriceTierUpdate}
          />
        </div>
        <AddOnPageDropDown
          data={["Monthly", "Annually"]}
          label="Billing"
          disabled={false}
          selectedValue={billingPeriod === "MONTHLY" ? "Monthly" : "Annually"}
          onSelect={(value) => {
            setBillingPeriod(value.toUpperCase());
            convertToModelPlan(plan!, value.toUpperCase());
          }}
        />
        {formData && formData!.tier_type === "Flat fee" && (
          <div className="flex flex-wrap space-x-5 items-center">

            <AddOnPageDropDown
              data={plan?.feature_mappings?.[0]?.feature?.type !== "boolean" ? ["Flat fee", "Tiered"] : ["Flat fee"]}
              selectedValue={formData!.tier_type}
              label="Pricing model"
              onSelect={(_) => {
                if (formData!.tier_type === "Flat fee") {
                  setInitialPriceState(formData!.price as number);
                  setFormData({
                    ...formData!,
                    tier_type: "Tiered",
                    price: initialPriceState ?? [
                      {
                        from: 0,
                        to: 1,
                        price: 0,
                      },
                      {
                        from: 2,
                        to: "&above",
                        price: 0,
                      },
                    ],
                  });
                } else {
                  setInitialPriceState(formData!.price as PriceTierV2[]);
                  setFormData({
                    ...formData!,
                    tier_type: "Flat fee",
                    price: initialPriceState ?? 0,
                  });
                }
              }}
            />
            <AddOnPageDropDown
              data={["INR"]}
              label="Currency"
              disabled={true}
              selectedValue="INR"
              onSelect={(value) => {
                console.log(value);
              }}
            />
            <div>
              <h3 className="font-medium">
                Price<span className="text-red-600">*</span>
              </h3>
              <InputField
                placeholder={"name"}
                value={formData!.price.toString()}
                type="number"
                onChange={(event) => {
                  let parsedValue = 0;
                  if (event.target.value.length < 1) {
                    parsedValue = 0;
                  } else {
                    parsedValue = Number.parseFloat(event.target.value);
                  }
                  setFormData({
                    ...formData!,
                    price: parsedValue,
                  });
                }}
              />
            </div>
          </div>
        )}
        {formData && formData!.tier_type === "Tiered" && (
          <div className="gap-10">
            <div className="flex space-x-5 items-top">
              <AddOnPageDropDown
                data={plan?.feature_mappings?.[0]?.feature?.type !== "boolean" ? ["Flat fee", "Tiered"] : ["Flat fee"]}
                selectedValue={formData!.tier_type}
                label="Pricing model"
                onSelect={(_) => {
                  if (formData!.tier_type === "Flat fee") {
                    setInitialPriceState(formData!.price as number);
                    setFormData({
                      ...formData!,
                      tier_type: "Tiered",
                      price: initialPriceState ?? [
                        {
                          from: 0,
                          to: 1,
                          price: 0,
                        },
                        {
                          from: 2,
                          to: "&above",
                          price: 0,
                        },
                      ],
                    });
                  } else {
                    setInitialPriceState(formData!.price as PriceTierV2[]);
                    setFormData({
                      ...formData!,
                      tier_type: "Flat fee",
                      price: initialPriceState ?? 0,
                    });
                  }
                }}
              />
              <AddOnPageDropDown
                data={["INR"]}
                label="Currency"
                selectedValue="INR"
                disabled={true}
                onSelect={(value) => {
                  console.log(value);
                }}
              />
            </div>
            <div className="space-y-4 mt-5">
              {(formData!.price as []).map(
                (tier: PriceTierV2, index: number) => (
                  <div key={index}>
                    <div key={index} className="flex gap-4 items-center">
                      <div>
                        {index === 0 && (
                          <h3 className="font-medium">
                            From (units)
                            <span className="text-red-600">*</span>
                          </h3>
                        )}
                        <InputField
                          placeholder={"From"}
                          value={tier.from.toString()}
                          disabled={true}
                        // onChange={(event) =>
                        //   handleTierChange(index, "from", event.target.value)
                        // }
                        />
                      </div>
                      <div>
                        {index === 0 && (
                          <h3 className="font-medium">
                            To<span className="text-red-600">*</span>
                          </h3>
                        )}
                        <InputField
                          placeholder={"To"}
                          value={tier.to.toString()}
                          type={
                            index === (formData!.price! as []).length - 1
                              ? "text"
                              : "number"
                          }
                          onChange={(event) => {
                            if (tier.to !== "&above") {
                              handleTierChange(index, "to", event.target.value);
                            }
                          }}
                        />
                      </div>
                      <div>
                        {index === 0 && (
                          <h3 className="font-medium">
                            Price per unit
                            <span className="text-red-600">*</span>
                          </h3>
                        )}
                        <InputField
                          placeholder={"Price"}
                          value={tier.price.toString()}
                          type="number"
                          onChange={(event) =>
                            handleTierChange(index, "price", event.target.value)
                          }
                        />
                      </div>
                      {index !== 0 && (
                        <button
                          onClick={() => {
                            removeTier(index);
                          }}
                          className="text-red-600"
                        >
                          <DeleteForever />
                        </button>
                      )}
                    </div>
                    <Spacer height={10} />
                    {index !== (formData!.price as []).length - 1 && (
                      <Divider sx={{ width: "860px" }} />
                    )}
                  </div>
                )
              )}
              <div>
                <style>{buttonHoverStyle}</style>
                <button
                  onClick={() => {
                    addTier();
                  }}
                  className="mt-2 text-orange-600 "
                >
                  + Add another tier
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={alertOpen}
        onDurationChange={() => setAlertOpen(false)}
        autoHideDuration={1000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity={snackBarInfo!.isError ? "error" : "success"}
          sx={{ width: "100%" }}
          onClose={() => setAlertOpen(false)}
        >
          {snackBarInfo.title}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const buttonHoverStyle = `
  button:hover {
    padding:"5px";
    background-color: #F6DDD6;
  }
`;

export default AddonEditPage;
