import { getClient } from "../configs/axios_configs";
import { defineCancelApiObject } from "../configs/axios_utils";
import { Feature } from "../models/model_product_detail";

export const FeatureAPI = {
  getAll: async function (cancel = false, slug: string) {
    console.log("Slug", slug);
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.get(`/gw/features/?slug=${slug}`, {
      headers: {
        "X-Environment-ID": envId,
      },
      data: { slug: slug },
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    const result: Feature[] = response.data["data"];
    return result;
  },
};

const cancelApiObject = defineCancelApiObject(FeatureAPI);
