import { Box, Card, Dialog, DialogContent } from "@mui/material";
import Spacer from "../common/util/spacer";
import FeatureChip from "./feature_chip";
import { Plan } from "../../api/models/model_product_detail";
import { useState } from "react";
import { getPlanPriceDetails } from "../../utils/helper_functions_components";
import PlanDetailPage from "@/pages/price/price_detail";

interface PlanCardProps {
  plan: Plan;
  billing: string;
}

export default function PlanCard({ plan, billing }: PlanCardProps) {
  const [open, setOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const hasMoreThanFiveFeatures =
    plan.feature_mappings && plan.feature_mappings.length > 5;
  const remainingFeaturesCount = hasMoreThanFiveFeatures
    ? plan.feature_mappings.length - 5
    : 0;

  return (
    <div>
      <Card
        // onClick={() => {
        //   setOpen(true);
        // }}
        elevation={2}
        sx={{
          alignContent: "start",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 className={"font-inter font-bold text-lg"}>{plan.name}</h2>
          {/* <BorderColorOutlined /> */}
        </Box>
        <Spacer height={10} />

        {getPlanPriceDetails(plan.prices!, billing === "Monthly")}

        <Spacer height={10} />

        {plan.feature_mappings && (
          <>
            {plan.feature_mappings
              .slice(0, showMore ? plan.feature_mappings.length : 5)
              .map((feature, index) => (
                <FeatureChip key={index} feature={feature} />
              ))}
          </>
        )}

        {hasMoreThanFiveFeatures && (
          <div className={"flex justify-start my-2"}>
            <button
              onClick={() => setShowMore(!showMore)}
              className={"font-semibold text-orange-500 text-[0.95rem]"}
            >
              {showMore
                ? "- Show Less"
                : `+ Show ${remainingFeaturesCount} More`}
            </button>
          </div>
        )}
      </Card>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiDialog-paper": {
            width: "70%", // Custom width
            maxWidth: "none", // Remove the default maxWidth
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <PlanDetailPage plan={plan} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
