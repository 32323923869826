import { getClient } from "../configs/axios_configs";
import { defineCancelApiObject } from "../configs/axios_utils";

export const ImportAPI = {
  import: async function (cancel = false, payload: any) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "POST",
      url: "/gw/data/import",
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      data: payload,
      signal: cancel
        ? cancelApiObject[this.import.name].handleRequestCancellation().signal
        : undefined,
    });
    const result: boolean = response.status === 200 ? true : false;
    return result;
  },
};

const cancelApiObject = defineCancelApiObject(ImportAPI);
