import {
  Box,
  Dialog,
  DialogContent,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import FlexTextField from "../common/textfield/textfield";
import Spacer from "../common/util/spacer";
import PriceFormSelector from "./widgets/price_form_selector";
import {
  AttachMoney,
  MonetizationOnOutlined,
  MoneyOffOutlined,
} from "@mui/icons-material";
import FlexSwitch from "../common/switch/flex_switch";
import CountryPriceComponent from "./widgets/country_form";

export interface FormModel {
  name: string;
  description: string;
  slug: string;
  pricingModel?: string;
  billingAnnually: boolean;
  billingMonthly: boolean;
  annualPrice: number;
  monthlyPrice: number;
  planType?: ModelDropDown;
  billingPeriod?: ModelDropDown;
  paymentModel?: ModelDropDown;
  allowPriceLocalization?: boolean;
  priceLocalization?: [];
}

// interface Localization {
//   name: string;
//   currency: string;
//   monthlyPrice: number;
//   annualPrice: number;
// }
interface ModelDropDown {
  id: string;
  name: string;
}

interface PriceFormDialogProps {
  open: boolean;
  onClose: (event: any) => void;
}

export default function PriceFormPopup({
  open,
  onClose,
}: PriceFormDialogProps) {
  const [formValue, setFormValue] = useState<FormModel>({
    name: "",
    description: "",
    slug: "plan-",
    annualPrice: 0,
    monthlyPrice: 0,
    billingAnnually: false,
    billingMonthly: false,
  });

  const handleChange = (
    field: keyof FormModel,
    value: string | number | ModelDropDown | boolean
  ) => {
    setFormValue((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleSlug = (value: string) => {
    const data = value.trim().toLowerCase().split(" ");
    let slug = "plan-";
    for (let i = 0; i < data.length; i++) {
      if (i < data.length - 1) slug += data[i] + "-";
      else slug += data[i];
    }
    setFormValue((prevState) => ({
      ...prevState,
      slug: slug,
    }));
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: "20px",
          }}
        >
          <Box
            component="form"
            sx={{
              overflowX: "visible",
              overflowY: "scroll",
              "& .MuiTextField-root": { width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <FlexTextField
              textLabel="Name"
              placeHolder="Enter Name"
              value={formValue.name}
              onChange={(value) => {
                handleChange("name", value);
                handleSlug(value);
              }}
            />
            <Spacer height={20} />
            <FlexTextField
              textLabel="Description"
              value={formValue.description}
              placeHolder="Enter Description"
              onChange={(value) => handleChange("description", value)}
            />
            <Spacer height={20} />
            <FlexTextField
              textLabel="Plan Slug"
              value={formValue.slug}
              placeHolder="Enter Description"
              onChange={(value) => console.log(`${value}`)}
            />
            <Spacer height={20} />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Plan Type
            </Typography>
            <Spacer height={5} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <PriceFormSelector
                label="Free"
                icon={<MoneyOffOutlined />}
                isSelected={formValue.planType?.id === "free"}
                subtitle="Subscription without payment"
                typeOfSelector="normal"
                onClick={() => {
                  const plan: ModelDropDown = {
                    id: "free",
                    name: "free",
                  };
                  handleChange("planType", plan);
                }}
              />
              <Spacer width={20} />
              <PriceFormSelector
                label="Paid"
                icon={<MonetizationOnOutlined />}
                typeOfSelector="normal"
                isSelected={formValue.planType?.id === "paid"}
                subtitle="Subscription after payment"
                onClick={() => {
                  const plan: ModelDropDown = {
                    id: "paid",
                    name: "paid",
                  };
                  handleChange("planType", plan);
                }}
              />
              <Spacer width={20} />
              <PriceFormSelector
                label="Custom"
                typeOfSelector="normal"
                icon={<MonetizationOnOutlined />}
                isSelected={formValue.planType?.id === "custom"}
                subtitle="Variation between customers"
                onClick={() => {
                  const plan: ModelDropDown = {
                    id: "custom",
                    name: "custom",
                  };
                  handleChange("planType", plan);
                }}
              />
            </Box>
            <Spacer height={20} />
            <FlexTextField
              textLabel="Pricing Model Type"
              placeHolder="Enter Description"
              isDropDown={true}
              value={formValue.pricingModel}
              onChange={(value) => handleChange("pricingModel", value)}
            >
              <MenuItem value="flat-rate">Flat Rate</MenuItem>
              <MenuItem value="tiered">Tiered</MenuItem>
              <MenuItem value="volume">Volume</MenuItem>
            </FlexTextField>
            <Spacer height={20} />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Billing Period
            </Typography>
            <Spacer height={5} />
            <Box
              sx={{
                display: "flex",
              }}
            >
              <PriceFormSelector
                label="Monthly"
                isSelected={formValue.billingMonthly}
                typeOfSelector="checkbox"
                onClick={() => {
                  // const plan: ModelDropDown = {
                  //   id: "monthly",
                  //   name: "monthly",
                  // };
                  handleChange("billingMonthly", !formValue.billingMonthly);
                }}
              />
              <Spacer width={20} />
              <PriceFormSelector
                label="Annually"
                typeOfSelector="checkbox"
                isSelected={formValue.billingAnnually}
                onClick={() => {
                  // const plan: ModelDropDown = {
                  //   id: "annually",
                  //   name: "annually",
                  // };
                  handleChange("billingAnnually", !formValue.billingAnnually);
                }}
              />
            </Box>
            <Spacer height={20} />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              Payment Model
            </Typography>
            <Spacer height={5} />
            <Box
              sx={{
                display: "flex",
              }}
            >
              <PriceFormSelector
                label="Base-Charge"
                isSelected={formValue.paymentModel?.id === "base-charge"}
                subtitle="A recurring fixed-fee"
                typeOfSelector="radio"
                onClick={() => {
                  const plan: ModelDropDown = {
                    id: "base-charge",
                    name: "base-charge",
                  };
                  handleChange("paymentModel", plan);
                }}
              />
              <Spacer width={20} />
              <PriceFormSelector
                label="Usage-based charge"
                typeOfSelector="radio"
                subtitle="According their usage"
                isSelected={formValue.paymentModel?.id === "usage-charge"}
                onClick={() => {
                  const plan: ModelDropDown = {
                    id: "usage-charge",
                    name: "uasge based charge",
                  };
                  handleChange("paymentModel", plan);
                }}
              />
            </Box>
            <Spacer height={20} />
            {formValue.billingMonthly && (
              <>
                <FlexTextField
                  textLabel="Monthly Price"
                  placeHolder="Enter Montly Price"
                  inputType="number"
                  value={formValue.monthlyPrice.toString()}
                  leadingIcon={<AttachMoney />}
                  onChange={(value) => handleChange("monthlyPrice", +value)}
                />
                <Spacer height={20} />
              </>
            )}

            {formValue.billingAnnually && (
              <>
                <FlexTextField
                  textLabel="Annual Price"
                  placeHolder="Enter Annual Price"
                  inputType="number"
                  value={formValue.annualPrice.toString()}
                  leadingIcon={<AttachMoney />}
                  onChange={(value) => handleChange("annualPrice", +value)}
                />
                <Spacer height={20} />
              </>
            )}
            <FlexSwitch
              title="Price Localization"
              subTitle="Select a country and the currency that will be used to bill its customer"
              onChange={(value) =>
                handleChange("allowPriceLocalization", value)
              }
            />
            {formValue.allowPriceLocalization && (
              <CountryPriceComponent
                showMontly={formValue.billingMonthly}
                showAnnually={formValue.billingAnnually}
              />
            )}
          </Box>
          {/* <Box width={350}>
            <Typography variant="h6" style={{ marginTop: "16px" }}>
              Name: {formValue.name}
            </Typography>
            <Spacer height={10} />
            <Typography variant="h6" style={{ marginTop: "16px" }}>
              Description: {formValue.description}
            </Typography>
            <Spacer height={10} />
            <Typography variant="h6" style={{ marginTop: "16px" }}>
              Monthly Price: {formValue.monthlyPrice}
            </Typography>
            <Spacer height={10} />
            <Typography variant="h6" style={{ marginTop: "16px" }}>
              Annual Price: {formValue.annualPrice}
            </Typography>
          </Box> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
