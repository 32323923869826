import { useState } from "react";

interface Props {
  values: string[];
  defaultValue: string;
  onChanged?: (value: string, index: number) => void;
}

const ChipGroup: React.FC<Props> = ({
  values,
  defaultValue,
  onChanged = () => {},
}) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <div className={"flex gap-4 p-2 border rounded-md"}>
      {values.map((chipText, index) => {
        const active = chipText === value;

        return (
          <div
            onClick={() => {
              setValue(chipText);
              onChanged(value, index);
            }}
            key={index}
            className={`select-none px-4 py-2 font-semibold rounded-md min-w-28 btn text-center ${
              active
                ? "border border-orange-600 text-orange-600 bg-opacity-10 bg-orange-500"
                : "text-gray-500"
            }`}
          >
            {chipText}
          </div>
        );
      })}
    </div>
  );
};
export default ChipGroup;
