import {Theme} from "@supabase/auth-ui-shared";

const customTheme: Theme = {
  default: {
    colors: {
      brand: "hsla(14, 81%, 60%, 1)",
      brandAccent: "hsla(14, 88%, 50%, 1)",
      brandButtonText: "white",
      inputBorder: "hsla(0, 0%, 87%, 1)",
      inputBackground: "hsla(0, 0%, 100%, 1)",
      defaultButtonBorder: "hsla(0, 0%, 100%, 1)",
      inputPlaceholder: "hsla(0, 0%, 74%, 1)",
    },
    borderWidths: {
      inputBorderWidth: "0px",
      buttonBorderWidth: "0px",
    },
    fontSizes: {
      baseButtonSize: "16px",
    },
    fonts: {
      bodyFontFamily: "Inter",
      buttonFontFamily: "Inter",
      labelFontFamily: "Inter",
      inputFontFamily: "Inter",
    },
    radii: {
      inputBorderRadius: "5px",
      borderRadiusButton: "5px",
    },
    space: {
      buttonPadding: "7px",
      emailInputSpacing: "5px",
      inputPadding: "10px",
      labelBottomMargin: "5px",
      anchorBottomMargin: "10px",
    },
  },
};

export default customTheme;
