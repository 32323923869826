import React, { ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import supabase from "../utils/supabase";
import { CircularProgress } from "@mui/material";
import { useUser } from "../context/UserContext";
import FlexColors from "@/utils/colors.ts";

interface AuthMiddlewareProps {
  children: ReactNode;
  requiredRole: string[];
}

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({
  children,
  requiredRole,
}) => {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const user = useUser();

  useEffect(() => {
    const checkLogin = async () => {
      try {
        setLoading(true);
        const {
          data: { user },
        } = await supabase.auth.getUser();
        setIsLoggedIn(!!user);
      } catch (error) {
        console.error("Error checking authentication:", error);
      } finally {
        setLoading(false);
      }
    };

    checkLogin();
  }, []);

  if (loading) {
    // Render loading indicator while checking authentication
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000, // Ensure it's above other content
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: to dim the background
        }}
      >
        <CircularProgress size={50} style={{ color: FlexColors.primary }} />
      </div>
    );
  }

  if (!isLoggedIn) {
    // Redirect to login page if user is not authenticated
    return <Navigate to="/login" />;
  }

  if (requiredRole && !requiredRole.includes(user.user.role)) {
    return <Navigate to="/not-authorized" />;
  }

  // Render children if user is authenticated
  return <>{children}</>;
};

export default AuthMiddleware;
