import { getClient } from "../configs/axios_configs";
import { defineCancelApiObject } from "../configs/axios_utils";

export const ExportAPI = {
  export: async function (cancel = false, payload: any) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "POST",
      url: "/gw/data/export",
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      data: payload,
      signal: cancel
        ? cancelApiObject[this.export.name].handleRequestCancellation().signal
        : undefined,
    });
    const result: Map<string, any>[] = response.data;
    return result;
  },
};

const cancelApiObject = defineCancelApiObject(ExportAPI);
