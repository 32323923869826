import { Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import supabase from "../../../utils/supabase";
import { ConfigProvider, Menu, Tooltip } from "antd";
import {
  RiDashboardHorizontalLine,
  RiDoubleQuotesR,
  RiUploadCloud2Line,
} from "react-icons/ri";
import { GrCatalogOption, GrTest } from "react-icons/gr";
import { BsFillPersonFill } from "react-icons/bs";
import { SiVitest } from "react-icons/si";
import { MdOutlineInsights } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import { IoIosLogOut } from "react-icons/io";
import { useUser } from "@/context/UserContext";
import { DollarSign, FilePlus } from "lucide-react";
import { LuUsers2 } from "react-icons/lu";

const SideNavBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const [collapsed, setCollapsed] = useState(false);

  const userContext = useUser();

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  const menuItems = [
    {
      icon: <DollarSign className={"text-xl size-6"} />,
      key: "/price",
      label: "Pricing Page",
      roles: ["sales", "admin"],
      onClick: () => navigate("/price"),
    },
    {
      icon: <GrCatalogOption className={"text-xl size-6"} />,
      key: "/plans",
      label: "Plans",
      roles: ["sales", "admin"],
      onClick: () => navigate("/plans"),
    },
    {
      key: "/addon",
      label: "Add-ons",
      roles: ["sales", "admin"],
      icon: <FilePlus className={"text-xl size-6"} />,
      onClick: () => navigate("/addon"),
    },
    {
      key: "/quotes",
      label: "Quotes",
      roles: ["sales", "admin"],
      icon: <RiDoubleQuotesR className={"text-xl size-6"} />,
      onClick: () => navigate("/quotes"),
    },

    {
      key: "/import-export",
      label: "Import-Export",
      roles: ["admin"],
      icon: <RiUploadCloud2Line className={"text-xl size-6"} />,
      onClick: () => navigate("/import-export"),
    },
    {
      key: "/customers",
      label: "Customers",
      roles: ["admin"],
      icon: <LuUsers2 className={"text-xl size-6"} />,
      onClick: () => navigate("/customers"),
    },
    {
      key: "1",
      label: (
        <span>
          Dashboard{" "}
          <span className="italic text-[0.65rem] text-opacity-15 opacity-50">
            {"   (coming soon)"}
          </span>
        </span>
      ),
      roles: ["sales", "admin"],
      icon: <RiDashboardHorizontalLine className={"text-xl size-6"} />,
      disabled: true,
    },
    {
      key: "3",
      label: (
        <span>
          Experiments
          <span className="italic text-[0.65rem] text-opacity-15 opacity-50">
            {"   (coming soon)"}
          </span>
        </span>
      ),
      roles: ["sales", "admin"],
      icon: <GrTest className={"text-xl size-6"} />,
      disabled: true,
    },
    {
      key: "4",
      label: (
        <span>
          Customers
          <span className="text-[0.65rem] text-opacity-15 opacity-50">
            {"   (coming soon)"}
          </span>
        </span>
      ),
      roles: ["sales", "admin"],
      icon: <BsFillPersonFill className={"text-xl size-6"} />,
      disabled: true,
    },
    {
      key: "5",
      label: (
        <span>
          Integrations
          <span className="italic text-[0.65rem] text-opacity-15 opacity-50">
            {"   (coming soon)"}
          </span>
        </span>
      ),
      roles: ["sales", "admin"],
      icon: <SiVitest className={"text-xl size-6"} />,
      disabled: true,
    },
    {
      key: "6",
      label: (
        <span>
          AI Insights
          <span className="italic text-[0.65rem] text-opacity-15 opacity-50">
            {"   (coming soon)"}
          </span>
        </span>
      ),
      roles: ["sales", "admin"],
      icon: <MdOutlineInsights className={"text-xl size-6"} />,
      disabled: true,
    },
  ];

  return (
    <aside id="sidebar" className="h-screen hidden md:block">
      <nav className="h-[calc(100%-5.0rem)] max-w-64 flex flex-col border-r shadow-sm transition-all duration-300 ease-in-out">
        <div
          className={`w-full p-2 pb-2 flex gap-2 ${
            collapsed ? "justify-center" : "justify-end"
          } items-center`}
        >
          <button
            className={"text-xl p-2"}
            onClick={() => {
              setCollapsed((prev) => !prev);
            }}
          >
            {collapsed ? <FaAnglesRight /> : <FaAnglesLeft />}
          </button>
        </div>

        <Divider />

        <ul
          className={`flex-1 overflow-y-auto transition-all duration-300 ease-in-out ${
            collapsed ? "w-20" : "w-64"
          }`}
        >
          <ConfigProvider
            theme={{
              components: {
                Menu: {},
              },
              token: {
                colorPrimary: "#E85426",
                colorText: "#8A8A8A",
              },
            }}
          >
            <Menu
              selectedKeys={[selectedKey]}
              mode="inline"
              items={menuItems
                .filter((item) => {
                  // console.log(userContext.user.role); // sales
                  // console.log(item.roles); // ['sales','admin']
                  // console.log(item.roles.includes(userContext.user.role)); //false
                  if (item.roles) {
                    return item.roles.includes(userContext.user.role);
                  }
                  return true;
                })
                .map((item) => {
                  return {
                    ...item,
                    icon: collapsed ? (
                      <Tooltip placement="right" title={item.label}>
                        {item.icon}
                      </Tooltip>
                    ) : (
                      item.icon
                    ),
                    label: collapsed ? null : (
                      <span className={"font-semibold select-none"}>
                        {item.label}
                      </span>
                    ),
                  };
                })}
              onClick={(e) => {
                setSelectedKey(e.key);
                navigate(e.key);
              }}
              style={{ maxWidth: "16rem" }}
            />
          </ConfigProvider>
        </ul>

        <div className={"p-2"}>
          <button
            onClick={() => {
              supabase.auth.signOut();
              navigate("/login");
            }}
            className={
              "w-full p-2 px-4 font-inter gap-2 text-gray-500 rounded-md hover:bg-gray-200 flex items-center active:scale-95 transition-all duration-300 font-medium"
            }
          >
            {collapsed ? (
              <Tooltip placement="right" title={"Logout"}>
                <IoIosLogOut className={"text-xl"} />
              </Tooltip>
            ) : (
              <IoIosLogOut className={"text-xl"} />
            )}
            {collapsed ? "" : " Logout"}
          </button>
        </div>
      </nav>
    </aside>
  );
};

export default SideNavBar;
