import { AppBar, Box } from "@mui/material";
import UserProfile from "../user_profile/user_profile";
import Logo from "../logo/logo";

export default function Navbar() {
  return (
    <AppBar
      position="static"
      elevation={5}
      sx={{
        height: "4.5rem",
        backgroundColor: "white",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <Logo />
        {/* <CurrentPath /> */}
        <UserProfile />
      </Box>
    </AppBar>
  );
}
