export default function Logo() {
  return (
    <div
      style={{
        fontFamily: "Inter",
        display: "flex",
        alignContent: "center", // Use flexbox layout
        alignItems: "center",
        userSelect: "none",
      }}
    >
      <span style={{ marginRight: "5px", fontSize: "50px", color: "black" }}>
        ❃
      </span>
      <span
        style={{
          fontSize: "24px",
          fontWeight: "700",
          color: "black",
          fontFamily: "Inter",
        }}
      >
        FLEX
      </span>
      <span
        style={{
          fontSize: "24px",
          fontWeight: "700",
          color: "#E85426",
          fontFamily: "Inter",
        }}
      >
        PRICE
      </span>
    </div>
  );
}
