import { ModelCustomer } from "@/api/models/model_customer";
import { CustomerAPI } from "@/api/req/customer_api";
import FlexButton from "@/components/common/button/button";
import FlexTextField from "@/components/common/textfield/textfield";
import Spacer from "@/components/common/util/spacer";
import Regex from "@/utils/regex";
import { editSlug } from "@/utils/utility_functions";
import { Drawer } from "antd";
import { useEffect, useState } from "react";

interface CustomerDrawerProps {
  onSuccess: (value: any) => void;
  onError: (value: any) => void;
  onClose: () => void;
  customer?: ModelCustomer;
  drawerVisibility: boolean;
}

export default function CustomerDrawer({
  onSuccess,
  onError,
  onClose,
  customer,
  drawerVisibility,
}: CustomerDrawerProps) {
  const [customerName, setCustomerName] = useState<string>("");
  const [customerSlug, setCustomerSlug] = useState<string>("customer-");
  const [customerEmail, setCustomerEmail] = useState<string>("");

  const [addingCustomer, setAddingCustomer] = useState<boolean>(false);

  useEffect(() => {
    if (customer) {
      setCustomerName(customer!.name);
      setCustomerEmail(customer!.email);
      setCustomerSlug(customer!.slug);
    } else {
      setCustomerName("");
      setCustomerEmail("");
    }
  }, [customer]);

  const handleCustomerInsertion = () => {
    if (customerName !== "") {
      setAddingCustomer(true);
      CustomerAPI.createCustomer(false, {
        name: customerName,
        email: customerEmail,
        slug: customerSlug,
      })
        .then((response) => {
          setAddingCustomer(false);
          if (response.message) {
            onError({
              message: response.message,
            })
          }
          onSuccess({
            id: response["customerId"],
            name: customerName,
            slug: customerSlug,
            email: customerEmail,
          });
          setCustomerName("");
          setCustomerEmail("");
        })
    }
  };

  const handleCustomerEdit = () => {
    if (customerName !== "" && customerEmail !== "") {
      setAddingCustomer(true);
      CustomerAPI.editCustomer(false, customer!.id, {
        name: customerName,
        email: customerEmail,
        slug: customerSlug
      })
        .then((response) => {
          setAddingCustomer(false);
          if (response) {
            onSuccess({});
          } else {
            onError({});
          }
        })
        .catch((_) => {
          setAddingCustomer(false);
          onError({});
        });
    }
  };
  return (
    <Drawer
      title={customer ? "Edit Customer" : "Add Customer"}
      onClose={onClose}
      open={drawerVisibility}
    >
      <FlexTextField
        textLabel="Customer name"
        fontSize={16}
        value={customerName}
        placeHolder="Enter customer name"
        fullWidth
        onChange={(value) => {
          setCustomerName(value);
          setCustomerSlug(editSlug({ prefix: 'customer', value: value }))
        }}
      />
      <FlexTextField
        textLabel="Customer Slug"
        fontSize={16}
        value={customerSlug}
        placeHolder="customer-"
        fullWidth
        onChange={(_) => { }}
      />
      <Spacer height={20} />
      <FlexTextField
        textLabel="Customer email ID"
        fontSize={16}
        value={customerEmail}
        regex={Regex.EMAIL}
        placeHolder="Enter customer email ID"
        fullWidth
        onChange={(value) => {
          setCustomerEmail(value);
        }}
        onKeyStroke={() => { }}
      />
      <Spacer height={30} />

      <FlexButton
        text={customer ? "Save Changes" : "Add Customer"}
        textSize={16}
        height={40}
        isLoading={addingCustomer}
        onClick={() => {
          if (customer) {
            handleCustomerEdit();
          } else {
            handleCustomerInsertion();
          }
        }}
      />
    </Drawer>
  );
}
