import FileSaver from "file-saver";
import { ModelGuardRail } from "../api/models/model_guardrail";
import ExcelJS from "exceljs";
import { PriceTier } from "@/api/models/model_product_detail";
import { PriceTierV2 } from "@/pages/addon/AddOnPage";


export function formatDate(data: Date): string {
  return new Date(data).toLocaleDateString("en-GB");
}

export function formatNumberUS(data: number): string {
  let formattedPrice = "";
  if (data >= 1_000_000_000) {
    formattedPrice =
      (data / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (data >= 1_000_000) {
    formattedPrice = (data / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (data >= 1_000) {
    formattedPrice = (data / 1_000).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    formattedPrice = data.toString();
  }

  return formattedPrice;
}
export function formatPrice(data?: number): string {
  let formattedPrice: string = formatNumber(data);

  return "Rs. " + formattedPrice;
}

export function formatNumber(number?: number): string {
  // Convert the number to a string

  if (number) {
    let numStr = number.toString();

    // Split the number into integer and decimal parts if a decimal exists
    let [integerPart, decimalPart] = numStr.includes(".")
      ? numStr.split(".")
      : [numStr, null];

    // Handle the part before the last 3 digits
    let lastThreeDigits = integerPart.substring(integerPart.length - 3);
    let otherDigits = integerPart.substring(0, integerPart.length - 3);

    // Apply comma to the remaining digits in pairs
    if (otherDigits !== "") {
      lastThreeDigits = "," + lastThreeDigits;
      otherDigits = otherDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Combine the formatted integer part with the decimal part (if it exists)
    return decimalPart !== null
      ? otherDigits +
          lastThreeDigits +
          "." +
          decimalPart.substring(0, 2).padEnd(2, "0")
      : otherDigits + lastThreeDigits;
  } else {
    return "0";
  }
}

export const validateEffectValue = (
  value: number,
  operator: string,
  effectValue: number
): boolean => {
  switch (operator) {
    case "LESS_THAN_OR_EQUAL_TO":
      return value <= effectValue;
    case "MORE_THAN_OR_EQUAL_TO":
      return value >= effectValue;
    case "LESS_THAN":
      return value < effectValue;
    case "MORE_THAN":
      return value > effectValue;
    default:
      return false;
  }
};

export const validateDiscountString = (
  valid: boolean,
  guardRail: ModelGuardRail
): string => {
  const operator = guardRail.operator;
  if (!valid && operator === "LESS_THAN_OR_EQUAL_TO")
    return "Discount exceeds the maximum value";
  else if (!valid && operator === "MORE_THAN_OR_EQUAL_TO")
    return "Discount is less than the minimum value";
  else if (!valid && operator === "LESS_THAN") return "less than";
  else if (!valid && operator === "MORE_THAN") return "more than";
  else return "invalid";
};

export const getLicenseKey = (tab: string): string => {
  switch (tab) {
    case "plan":
      return "M7WtmnXqlzIh62h6aYz80UWwb4Ht1N";
    case "price":
      return "3DzHoox4HqnuXcpdmjmgxNRGRR0RWP";
    case "feature":
      return "2tzeM0vIEIITBYCuSStqjhJnhJIhfi";
    case "feature_mapping":
      return "HwXBfGhJ7Qq4qikGTTeMcUqkBocl5V";
    default:
      return "2tzeM0vIEIITBYCuSStqjhJnhJIhfi";
  }
};

interface ExportToExcelProps {
  json: any[];
  fileName: string;
}

export const exportToExcel = async ({
  json,
  fileName
}: ExportToExcelProps) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("fileName");

  const headers = Object.keys(json[0]);

  // Catch Headers
  worksheet.addRow(headers);

  // Color Headers
  headers.forEach((head, index) => {
    console.log(head);
    worksheet.getCell(1, index + 1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4169E1" },
    };
    worksheet.getCell(1, index + 1).font = {
      bold: true,
      color: { argb: "FFFFFF" },
    };
  });

  json.forEach((item) => {
    let row = Object.values(item);
    worksheet.addRow(row);
  });

  const buffer = await workbook.xlsx.writeBuffer();

  const data = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
  FileSaver.saveAs(data, fileName + ".xlsx");
};

function transformData(data: any[]): any[][] {
  // Extract keys from the first object
  const keys = Object.keys(data[0]);

  // Create a new structure
  const transformedData = keys.map((key) => {
    // Extract values for each key
    const values = data.map((item) => item[key]);
    return [key, ...values];
  });

  return transformedData;
}

function separateFeatureAndKey(data: any): Map<string, any>[] {
  let feature: any = [];

  for (let json of data) {
    const result: { [key: string]: any } = {};
    json.forEach((item: any) => {
      result[item.Feature] = item.Value;
    });
    feature.push(result);
  }

  return feature;
}
interface ExportMultipleQuotationToExcelProps {
  data: any;
  fileName: string;
}

export const exportMultipleQuotationToExcel = ({
  data,
  fileName,
}: ExportMultipleQuotationToExcelProps): void => {
  const jsonData = separateFeatureAndKey(data);
  // Transform the data

  const transformedData = transformData(jsonData);

  // Create a new workbook
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  // Add the transformed data to the worksheet
  transformedData.forEach((row) => {
    worksheet.addRow(row);
  });

  // Add styling to the first row
  const firstRow = worksheet.getRow(1);
  const secondRow = worksheet.getRow(2);
  const thirdRow = worksheet.getRow(3);
  firstRow.eachCell((cell) => {
    cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "0096FF	" },
    };
  });

  secondRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "cce9ff" },
    };
  });

  thirdRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "cce9ff" },
    };
  });

  // Adjust column widths
  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    if (column)
      column.eachCell!({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
    column.width = maxLength + 2;
  });

  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      const data = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    })
    .catch((err) => {
      console.error("Error writing Excel file:", err);
    });
};

export function editSlug({ prefix, value }: { prefix: string, value: string }): string {
  value = value.trim();
  value = value.toLowerCase();
  const slug = value
    .split(' ')
    .join('-');
  
  return `${prefix}-${slug}`;
}

export function transformTiersToV2(tiers: PriceTier[]): PriceTierV2[] {
  let result = [];
  if(tiers.length == 0){
    result.push({
      from:0,
      to:"&above",
      price:0
    })
    return result;
  }
  for (let i = 0; i < tiers.length; i++) {
    if (i == 0) {
      result.push({
        from: 0,
        to: tiers[i].up_to,
        price: tiers[i].price,
      });
    } else if (i == tiers.length - 1) {
      result.push({
        from: ((tiers[i - 1].up_to as number) + 1) as number,
        to: "&above",
        price: tiers[i].price,
      });
    } else {
      result.push({
        from: ((tiers[i - 1].up_to as number) + 1) as number,
        to: tiers[i].up_to,
        price: tiers[i].price,
      });
    }
  }

  return result;
}
