interface Props {
  isActive?: boolean;
  onChanged?: (active: boolean) => void;
  activeText?: string;
  notActiveText?: string;
}

const Chip: React.FC<Props> = ({
  activeText = "Active",
  isActive = true,
  notActiveText = "Inactive",
}) => {
  return (
    <div
      className={`select-none border font-semibold rounded-full text-center cursor-pointer px-3 py-1 text-xs ${
        isActive
          ? "border-orange-600 text-[#E05C34] bg-[#fae3dc]"
          : "border-gray-500 text-gray-500 bg-gray-100"
      }`}
    >
      {isActive ? activeText : notActiveText}
    </div>
  );
};

export default Chip;
