import { getClient } from "../configs/axios_configs";
import { defineCancelApiObject } from "../configs/axios_utils";

export const EnvAPI = {
  getAll: async function (cancel = false) {
    const client = await getClient();

    const response = await client!.get("/gw/envs/", {
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });

    const result: ModelEnvironment[] = response.data["data"];
    localStorage.setItem("env_id", result[0].id);
    return result;
  },
};

const cancelApiObject = defineCancelApiObject(EnvAPI);
