import React, { useState } from "react";
import { Button, Card, Typography } from "@mui/material";
import { ContentCopy, LibraryAddCheckOutlined } from "@mui/icons-material";
import FlexColors from "@/utils/colors";

interface CopyButtonProps {
  textToCopy: string;
  textSize?: number;
  iconColor?: string;
  textColor?: string;
  padding?: number;
  showBackground?: boolean;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  textToCopy,
  textSize = 16,
  padding = 1,
  textColor = "black",
  showBackground = true,
  iconColor = FlexColors.primary,
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (event: React.MouseEvent) => {
    event.stopPropagation();

    navigator.clipboard.writeText(textToCopy).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000); // Reset after 1 seconds
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  return (
    <div>
      <Card
        elevation={0}
        sx={{
          padding: padding,
          display: "flex",
          alignItems: "center",
          backgroundColor: showBackground ? "#F5F5F5" : null, // Set background color to grey
        }}
      >
        <Typography
          variant="body1"
          sx={{
            flexGrow: 1,
            fontFamily: "Inter",
            fontSize: textSize,
            color: textColor,
            userSelect: "none",
          }}
        >
          {textToCopy}
        </Typography>
        {copied ? (
          <Button
            startIcon={<LibraryAddCheckOutlined sx={{ color: iconColor }} />}
          />
        ) : (
          <Button
            startIcon={<ContentCopy sx={{ color: iconColor }} />}
            onClick={handleCopy}
          />
        )}
        {/* <Button startIcon={<ContentCopy />} onClick={handleCopy}/> */}
      </Card>
    </div>
  );
};

export default CopyButton;
