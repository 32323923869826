import { useState } from "react";
import { Typography, IconButton, Box } from "@mui/material";
import { ModelQuotation } from "../../api/models/model_quotation";
import {
  CheckBox,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Sell,
  Style,
  CheckBoxOutlineBlank,
  Delete,
} from "@mui/icons-material";
import Spacer from "../../components/common/util/spacer";
import QuotationFeatureCard from "./QuotationFeatureCard";
import FlexColors from "../../utils/colors";
import { formatNumber, formatPrice } from "../../utils/utility_functions";

interface QuotationCardProp {
  quotation: ModelQuotation;
  isSelected: boolean;
  onPlanSelected: () => void;
  onDelete?: () => void;
}

export default function QuotationCard({
  quotation,
  isSelected,
  onPlanSelected,
  onDelete,
}: QuotationCardProp) {
  const [isPlanCollapsed, setIsPlanCollapsed] = useState(false);
  const [isAddOnCollapsed, setIsAddOnCollapsed] = useState(true);

  const togglePlanCollapse = () => {
    setIsPlanCollapsed(!isPlanCollapsed);
  };

  const toggleAddOnCollapse = () => {
    setIsAddOnCollapsed(!isAddOnCollapsed);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        width: "300px",
        borderRadius: "10px",
        border: isSelected ? "2px solid #E85426" : "1px solid #E5E5E5",
        flexDirection: "column",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      }}
      onClick={onPlanSelected}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 20px 10px 20px",
        }}
      >
        <Typography fontWeight={700} fontSize={18}>
          {quotation.plan.name}
        </Typography>

        {onDelete && (
          <IconButton onClick={onDelete}>
            <Delete sx={{ color: FlexColors.primary }} />
          </IconButton>
        )}

        {!onDelete && (
          <div>
            {isSelected ? (
              <CheckBox
                sx={{
                  color: FlexColors.primary,
                }}
              />
            ) : (
              <CheckBoxOutlineBlank
                sx={{
                  color: "grey",
                }}
              />
            )}
          </div>
        )}
      </div>

      {quotation.strategy &&
        quotation.strategy.map((stratergy, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 20px 10px 20px",
            }}
          >
            <div
              style={{
                display: "flex",
                border: "1px solid #E85426",
                padding: "5px",
                alignItems: "center",
                backgroundColor: "#ffe6de",
              }}
            >
              <Sell sx={{ color: "#E85426", width: "12px", height: "12px" }} />
              <Spacer width={5} />
              <Typography fontSize={12} color={"#E85426"} fontWeight={600}>
                {stratergy.label}
              </Typography>
            </div>
          </div>
        ))}

      <Spacer height={10} />
      <div
        style={{
          borderRadius: "10px",
          justifyContent: "start",
          padding: "10px 10px 10px 10px",
          marginLeft: "10px",
          marginRight: "10px",
          backgroundColor: "white",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography fontSize={14} fontWeight={600}>
          Fixed Charges
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={14} fontWeight={500} color={"gray"}>
            One-time cost:
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={quotation.discounted_fixed_price ? 500 : 700}
            color={quotation.discounted_fixed_price ? "gray" : "black"}
            sx={{
              textDecoration: quotation.discounted_fixed_price
                ? "line-through"
                : "null",
            }}
          >
            {formatPrice(quotation!.computed_fixed_price)}
          </Typography>
        </div>
        {quotation.discounted_fixed_price &&
          quotation.discounted_fixed_price > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Style
                  sx={{
                    color: FlexColors.primary,
                    width: "20px",
                    height: "20px",
                  }}
                />
                <Spacer width={10} />
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  color={FlexColors.primary}
                >
                  Discounted Price:
                </Typography>
              </div>
              <Typography
                fontSize={14}
                fontWeight={700}
                color={FlexColors.primary}
              >
                {formatPrice(quotation.discounted_fixed_price)}
              </Typography>
            </div>
          )}
      </div>
      <Spacer height={10} />
      <div
        style={{
          borderRadius: "10px",
          justifyContent: "space-between",
          padding: "10px 10px 10px 10px",
          marginLeft: "10px",
          marginRight: "10px",
          backgroundColor: "white",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={14} fontWeight={600}>
            Recurring Charges
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={quotation.discounted_monthly_price ? 500 : 700}
            color={quotation.discounted_monthly_price ? "gray" : "black"}
            sx={{
              textDecoration: quotation.discounted_monthly_price
                ? "line-through"
                : "null",
            }}
          >
            {formatPrice(quotation.computed_monthly_price)}
          </Typography>
        </div>
        {quotation.discounted_monthly_price &&
          quotation.discounted_monthly_price > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Style
                  sx={{
                    color: FlexColors.primary,
                    width: "20px",
                    height: "20px",
                  }}
                />
                <Spacer width={10} />
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  color={FlexColors.primary}
                >
                  Discounted Price:
                </Typography>
              </div>
              <Typography
                fontSize={14}
                fontWeight={700}
                color={FlexColors.primary}
              >
                {formatPrice(quotation.discounted_monthly_price)}
              </Typography>
            </div>
          )}
      </div>
      <Spacer height={10} />

      <div
        style={{
          marginLeft: "10px",
          marginRight: "10px",
          padding: "10px 10px 10px 10px",
          borderRadius: "10px",
          backgroundColor: "white",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={14} fontWeight={700}>
            Plan
          </Typography>
          <IconButton onClick={togglePlanCollapse}>
            {!isPlanCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={14} fontWeight={500} color={"gray"}>
            Base Charge
          </Typography>
          <Typography fontSize={14} fontWeight={500} color={"gray"}>
            {quotation.plan.prices &&
              formatPrice(quotation.plan.prices[0].monthly_price)}
          </Typography>
        </div>
        {isPlanCollapsed &&
          quotation.plan.feature_mappings?.map((feature, index) => (
            <div>
              <QuotationFeatureCard key={index} feature={feature} />
            </div>
          ))}
      </div>
      <Spacer height={20} />
      {quotation.add_ons && (
        <div
          style={{
            marginLeft: "10px",
            marginRight: "10px",
            padding: "10px 10px 10px 10px",
            borderRadius: "10px",
            backgroundColor: "white",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontSize={14} fontWeight={700}>
              Add-On
            </Typography>
            <IconButton onClick={toggleAddOnCollapse}>
              {isAddOnCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
            </IconButton>
          </div>
          {isAddOnCollapsed &&
            quotation.add_ons &&
            quotation.add_ons.map((addon, index) => (
              <div
                key={index}
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  marginTop: "10px",
                }}
                className="items-start px-2 py-2.5 text-base font-medium leading-6 rounded border-red-500 border-solid bg-red-500 bg-opacity-10 border-l-[3px]"
              >
                <div style={{ display: "flex" }}>
                  <Typography fontSize={14}>Price: </Typography>
                  <Spacer width={10} />
                  <Typography fontWeight={500} fontSize={14} color={"gray"}>
                    {formatPrice(addon!.feature_mappings![0].computed_price!)}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography fontSize={14}>Quantity: </Typography>
                  <Spacer width={10} />
                  <Typography fontWeight={500} fontSize={14} color={"gray"}>
                    {formatNumber(
                      addon!.feature_mappings![0].requested_quantity!
                    )}
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography fontSize={14}>Name: </Typography>
                  <Spacer width={10} />
                  <Typography fontWeight={500} fontSize={14} color={"gray"}>
                    {addon.feature_mappings![0].feature.name}
                  </Typography>
                </div>
              </div>
            ))}
        </div>
      )}
      <Spacer height={10} />
    </Box>
  );
}
