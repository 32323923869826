import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Alert,
} from "@mui/material";
import { Plan } from "../../../api/models/model_product_detail";
import { formatDate } from "../../../utils/utility_functions";

interface FlexTableProps {
  columnlabel: string[];
  data: Plan[];
}

export default function FLexTable({ columnlabel, data }: FlexTableProps) {
  return (
    <TableContainer component={Paper} className="table-container">
      <Table sx={{ borderRadius: 10 }}>
        <TableHead>
          <TableRow>
            {columnlabel.map((label, index) => (
              <TableCell
                key={index}
                sx={{
                  fontWeight: "bold",
                  color: "#E85426",
                }}
              >
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.length > 0 ? (
            data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.slug}</TableCell>
                <TableCell>
                  {row.status === "" ? "draft" : row.status}
                </TableCell>
                <TableCell>{formatDate(row.updated_at)}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columnlabel.length}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                  }}
                >
                  <Alert severity="warning">No data available</Alert>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
