import {Checkbox, Radio, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ReactNode} from "react";
import Spacer from "../../common/util/spacer";

interface PriceFormSelectorProps {
  isSelected: boolean;
  icon?: ReactNode;
  label: string;
  subtitle?: string;
  onClick: (event: any) => void;
  typeOfSelector: "radio" | "checkbox" | "normal";
}

export default function PriceFormSelector({
  icon,
  isSelected,
  label,
  subtitle,
  onClick,
  typeOfSelector,
}: PriceFormSelectorProps) {
  return (
    <div>
      <Box
        onClick={onClick}
        sx={{
          borderWidth: 1,
          borderRadius: 1,
          height: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderColor: isSelected ? "#E05D34" : "gray",
          backgroundColor: isSelected ? "#E05D341A" : "white",
          color: isSelected ? "#E05D34" : "gray",
          cursor: "pointer",
          padding: "10px",
        }}
      >
        {typeOfSelector === "radio" && (
          <Radio color="error" checked={isSelected} />
        )}
        {typeOfSelector === "checkbox" && (
          <Checkbox color="error" checked={isSelected} />
        )}
        {typeOfSelector === "normal" && icon}
        <Spacer width={5} />
          <Typography sx={{fontFamily: "Inter", fontWeight: "600"}}>
          {label}
        </Typography>
      </Box>
      <Spacer height={10} />
      <Typography
        sx={{
          display: "flex",
          fontStyle: "italic",
            fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "200",
          maxLines: 2,
        }}
      >
        {subtitle}
      </Typography>
    </div>
  );
}
