import React from "react";
import { Tooltip, Typography } from "@mui/material";
import FlexColors from "@/utils/colors";
import { PriceTier } from "@/api/models/model_product_detail";
import { formatNumberUS } from "@/utils/utility_functions";
import { PriceTierV2 } from "./AddOnPage";

function transformTiers(tiers: PriceTierV2[]): PriceTier[] {
  return tiers.map((tier, _) => {
    if (typeof tier.to === "string") {
      return {
        up_to: `${formatNumberUS(tier.from as number)}-${tier.to}`,
        price: tier.price,
      };
    } else {
      return {
        up_to: `${formatNumberUS(tier.from as number)}-${formatNumberUS(
          tier.to as number
        )}`,
        price: tier.price,
      };
    }
  });
}

const PriceCellRenderer: React.FC<{ params: any }> = ({ params }) => {
  return (
    <div className={"w-full flex justify-end"}>
      {typeof params.value === "number"   ? (
        `Rs ${params.value}`
      ) : (
        <div>
          {`Starts at Rs ${params.value[0]?.price ?? 0} `}
          {params.value.length > 0 && 
          <Tooltip
            componentsProps={{
              popper: {
                placement: "bottom-end",
              },
              tooltip: {
                sx: {
                  bgcolor: "white",
                  border: "1px solid",
                  borderColor: FlexColors.primary,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                  p: 1,
                },
              },
            }}
            title={
              <div>
                <Typography variant="body2" color="textSecondary">
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            paddingRight: "40px",
                          }}
                        >
                          Item
                        </th>
                        <th style={{ textAlign: "left", fontWeight: "bold" }}>
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transformTiers(params.value).map(
                        (item: PriceTier, index: number) => (
                          <tr key={index}>
                            <td style={{ paddingRight: "40px" }}>
                              {item.up_to}
                            </td>
                            <td>Rs.{item.price}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </Typography>
              </div>
            }
          >
            <button
              style={{
                border: `1px solid ${FlexColors.primary}`,

                background: "#F6DDD6",
              }}
              className="border border-orange-400 bg-orange-200 rounded px-1 py-0.5 text-xs"
            >
              +{params.value.length}
            </button>
          </Tooltip>
}
        </div>
      )}
    </div>
  );
};

export default PriceCellRenderer;
