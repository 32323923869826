import React, { useEffect, useRef, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { LuMoreVertical } from "react-icons/lu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import Chip from "@/components/common/chip/chip";
import { Alert, CircularProgress, Container, Snackbar } from "@mui/material";
import FlexColors from "@/utils/colors.ts";
import { ModelCustomer } from "@/api/models/model_customer";
import { CustomerAPI } from "@/api/req/customer_api";
import { ColDef } from "ag-grid-community";
import "../addon/index.css";
import { ModelSnackbar } from "../sales/QuotationPage";
import CustomerDrawer from "./CustomerDrawer";
import FlexButton from "@/components/common/button/button.tsx";
import Spacer from "@/components/common/util/spacer";

export interface PriceTierV2 {
  from: number | string;
  to: number | string;
  price: number;
}

export interface ModelTableData {
  name: string;
  slug: string;
  tier_type: string;
  status: string;
  price: PriceTierV2[] | number;
  updated_at: Date;
}

function formatDate(timestamp: string) {
  const date = new Date(timestamp);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

const ActionCellRenderer: React.FC<{
  onDelete: () => void;
  onEdit: () => void;
}> = ({ onDelete, onEdit }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <LuMoreVertical className={"text-xl "} />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={onEdit}>Edit</DropdownMenuItem>
        <DropdownMenuItem onClick={onDelete}>Delete</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const Customer = () => {
  const gridRef = useRef<AgGridReact>(null);

  const [loading, setLoading] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [customers, setCustomers] = useState<ModelCustomer[]>([]);
  const [snackBarInfo, setSnackBarInfo] = useState<ModelSnackbar>({
    title: "",
    isError: false,
  });
  useEffect(() => {
    getAllCustomers();
  }, []);

  const getAllCustomers = () => {
    setLoading(true);
    CustomerAPI.getAll().then((result: ModelCustomer[]) => {
      setCustomers(result);
      setLoading(false);
    });
  };

  const deleteCustomer = (props: any) => {
    setLoading(true);
    CustomerAPI.delete(false, props.data.id)
      .then((response) => {
        if (response) {
          setSnackBarInfo({
            title: "Customer deleted successfully",
            isError: false,
          });
          setLoading(false);
          setAlertOpen(true);
          setCustomers((prev) => {
            return prev.filter((item) => item.id !== props.data.id);
          });
        } else {
          setSnackBarInfo({
            title: "Customer deletion failed",
            isError: true,
          });
          setLoading(false);
          setAlertOpen(true);
        }
      })
      .catch((_) => {
        setSnackBarInfo({
          title: "Customer deletion failed",
          isError: true,
        });
        setLoading(false);
        setAlertOpen(true);
      });
  };

  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<ModelCustomer>();
  const colDefs: ColDef[] = [
    {
      headerName: "Name",
      field: "name",
      filter: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: (params: any) => (
        <div className="flex justify-center w-full items-center h-full ">
          <Chip isActive={params.value === "published"} />
        </div>
      ),
      filter: true,
      sortable: true,
      width: 100,
    },
    {
      headerName: "Slug",
      field: "slug",
      filter: true,
      sortable: true,
      flex: 1,
    },
    {
      headerName: "Last updated",
      field: "updated_at",
      valueGetter: (s: any) => formatDate(s.data.updated_at),
      filter: true,
      sortable: true,
      flex: 1,
    },
    {
      headerName: "",
      field: "actions",
      cellRenderer: (props: any) => (
        <ActionCellRenderer
          onDelete={() => deleteCustomer(props)}
          onEdit={() => {
            setSelectedCustomer(props.data);
            setDrawerVisibility(true);
          }}
        />
      ),
      pinned: "right",
      width: 100,
    },
  ];

  const onGridReady = () => {
    gridRef.current!.api.sizeColumnsToFit();
    // // Auto-size the Actions column to fit its content
    // params.columnApi.autoSizeColumn("actions");
  };

  return (
    <Container>
      <Spacer height={10} />
      <div className=" min-h-screen w-full">
        <div className="p-4 bg-white card flex justify-between items-center mt-8">
          <h1 className="text-3xl font-bold">Customers</h1>
          <FlexButton
            text="Add Customer"
            textSize={16}
            height={40}
            isLoading={false}
            onClick={() => {
              setSelectedCustomer(undefined); // Ensure drawer opens in "Add" mode
              setDrawerVisibility(true);
            }}
          />
        </div>

        <div className="ag-theme-quartz card mt-4  w-full  p-4 bg-white">
          <AgGridReact
            ref={gridRef}
            columnDefs={colDefs}
            rowData={customers}
            containerStyle={{
              height: "calc(100vh-5000px)",
            }}
            loading={loading}
            domLayout="autoHeight"
            loadingOverlayComponent={() => (
              <CircularProgress size={50} style={{ color: FlexColors.primary }} />
            )}
            onGridReady={onGridReady}
          />
        </div>
        <Snackbar
          open={alertOpen}
          onDurationChange={() => setAlertOpen(false)}
          autoHideDuration={3000}
          onClose={() => setAlertOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity={snackBarInfo!.isError ? "error" : "success"}
            sx={{ width: "100%" }}
            onClose={() => setAlertOpen(false)}
          >
            {snackBarInfo!.title}
          </Alert>
        </Snackbar>
        <CustomerDrawer
          drawerVisibility={drawerVisibility}
          customer={selectedCustomer}
          onSuccess={() => {
            setDrawerVisibility(false);
            getAllCustomers();
          }}
          onClose={() => {
            setDrawerVisibility(false);
          }}
          onError={(value) => {
            setSnackBarInfo({
              title: value.message,
              isError: true,
            });
            setAlertOpen(true);
          }}
        />
      </div>
    </Container>
  );
};

export default Customer;
