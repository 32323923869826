import { getClient } from "../configs/axios_configs";
import { defineCancelApiObject } from "../configs/axios_utils";
import { ModelCustomer } from "../models/model_customer";
import {
  ModelQuotation,
  ModelQuotationResponse,
} from "../models/model_quotation";

export const CustomerAPI = {
  postCustomerForm: async function (cancel = false, payload: any) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "POST",
      url: "/gw/products/customer/requirement",
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      data: payload,
      signal: cancel
        ? cancelApiObject[
            this.postCustomerForm.name
          ].handleRequestCancellation().signal
        : undefined,
    });
    const result: ModelQuotationResponse = response.data;
    return result;
  },
  postQuotationFeedback: async function (
    cancel = false,
    payload: any,
    product_id: string
  ) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "POST",
      url: `/gw/feedback/${product_id}/add`,
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      data: payload,
      signal: cancel
        ? cancelApiObject[
            this.postQuotationFeedback.name
          ].handleRequestCancellation().signal
        : undefined,
    });
    return response.status === 200 ? true : false;
  },
  getAll: async function (cancel = false) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "GET",
      url: `/gw/customers/?status=published`,
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    const result: ModelCustomer[] = response.data["data"];
    return result;
  },
  createCustomer: async function (cancel = false, payload: any) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "POST",
      url: `/gw/customers/submit`,
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      data: payload,
      signal: cancel
        ? cancelApiObject[this.createCustomer.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.data["data"];
  },
  editCustomer: async function (
    cancel = false,
    customerId: string,
    payload: any
  ) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "PUT",
      url: `/gw/customers/${customerId}`,
      data: payload,
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      signal: cancel
        ? cancelApiObject[this.editCustomer.name].handleRequestCancellation()
            .signal
        : undefined,
    });
    return response.status === 200 ? true : false;
  },
  saveCustomerQuotation: async function (cancel = false, payload: any) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "POST",
      url: `/gw/customers/quotations`,
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      data: payload,
      signal: cancel
        ? cancelApiObject[
            this.saveCustomerQuotation.name
          ].handleRequestCancellation().signal
        : undefined,
    });
    return response.data["data"];
  },
  getCustomerQuotation: async function (cancel = false, customer_id: string) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "GET",
      url: `/gw/quotations/customer/${customer_id}?status=published`,
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      signal: cancel
        ? cancelApiObject[
            this.getCustomerQuotation.name
          ].handleRequestCancellation().signal
        : undefined,
    });
    if (response.data["data"] === null) return [];
    const quotations: ModelQuotation[] = response.data["data"].map(
      (item: any) => {
        let id = item["id"];
        const quotes: ModelQuotation = JSON.parse(item["generated_plan"]);
        quotes.id = id;
        return quotes;
      }
    );
    return quotations;
  },
  deleteCustomerQuotation: async function (
    cancel = false,
    quotation_id: string
  ) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "DELETE",
      url: `/gw/quotations/${quotation_id}`,
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      signal: cancel
        ? cancelApiObject[
            this.deleteCustomerQuotation.name
          ].handleRequestCancellation().signal
        : undefined,
    });
    return response.data["data"];
  },
  delete: async function (cancel = false, customer_id: string) {
    const client = await getClient();
    const envId = localStorage.getItem("env_id");
    const response = await client!.request({
      method: "DELETE",
      url: `/gw/customers/${customer_id}`,
      headers: {
        "X-Environment-ID": envId,
        "Content-Type": "application/json",
      },
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.status === 200 ? true : false;
  },
};

const cancelApiObject = defineCancelApiObject(CustomerAPI);
